<template>
  <div>
    <div v-if="listMode">
      <div class="content-section introduction">
        <div class="feature-intro text-h6 font-weight-light">
          {{ pageTitleLabel | uppercase }}
          <v-divider/>
        </div>
      </div>

      <div ref="customFiltersRef">
        <slot name="customFilters" :data="filters" />
      </div>
      <v-toolbar dense flat>
        <v-spacer />
        <v-btn icon :title="tLabel('Applica filtri')" @click="onRefresh" v-if="!excludeButtons.refresh">
          <v-icon color="warning" medium>mdi-find-replace</v-icon>
        </v-btn>
        <v-icon large />
        <v-btn icon :title="tLabel('Cancella filtri')" @click="onCleanFilters" v-if="!excludeButtons.onCleanFilters">
          <v-icon color="warning" medium>mdi-broom</v-icon>
        </v-btn>
        <v-btn icon :title="tLabel('Aggiungi')" @click="addNewItem" v-if="!excludeButtons.add">
          <v-icon color="primary" medium>mdi-plus</v-icon>
        </v-btn>
        <v-btn icon :title="btn_title_exportCSV" @click="exportCSV" v-if="!excludeButtons.exportCsv">
          <v-icon color="success" medium>mdi-file-table-outline</v-icon>
        </v-btn>
        <slot name="extraButtons" :data="filters" />
      </v-toolbar>

      <template v-if="$scopedSlots.table">
        <slot name="table" :items="items" :errors="errors"></slot>
      </template>

      <v-data-table v-else :headers="columnsHeader" :items="items" :options.sync="tableOptions"
        :server-items-length="totalRecords" :loading="loading" ref="tosDataTable" :footer-props="{
          itemsPerPageText: 'Risultati per pagina',
          itemsPerPageOptions: [10, 50, 100, 250, 1000],
          showFirstLastPage: true,
        }">
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td v-if="!hasItemSlot('actions')">
                <div class="css-group-and-align-element css-centered">
                  <v-btn icon :title="tLabel('Modifica')" @click="onOpenItem(item.id)">
                    <v-icon color="primary" medium>mdi-pencil</v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn icon :title="tLabel('Elimina')" @click="
                    doConfirm(
                      'Confermi la cancellazione ? ',
                      'deleteItem',
                      item.id
                    )
                    " v-if="!excludeButtons.elimina">
                    <v-icon color="primary" medium>mdi-delete</v-icon>
                  </v-btn>
                  &nbsp;
                  <slot name="customButtons" :data="item" />
                </div>
              </td>
              <td v-else>
                <slot :name="'item.actions'" :data="item" />
              </td>

              <v-tooltip top v-for="col of columns" :key="col.value" :disabled="!col.tooltip || !item[col.value]">
                <template v-slot:activator="{ on, attrs }">
                  <td v-bind="attrs" v-on="on">
                    <template v-if="hasItemSlot(col.value)">
                      <slot :name="'item.' + col.value" :data="item" :value="item[col.value]" />
                    </template>
                    <template v-else-if="col.customTemplate">
                      <span v-html="col.customTemplate(item)"></span>
                    </template>
                    <template v-else-if="col.link">
                      <a href="#" @click.prevent="showInfo(item[col.value])">{{
                        item[col.value] ? item[col.value].label : ""
                      }}</a>
                    </template>
                    <template v-else>
                      {{ evaluateExpr(item, col) }}
                    </template>
                  </td>
                </template>
                <span style="color: white;">{{item[col.value]}}</span>
              </v-tooltip>

            </tr>
          </tbody>
        </template>

        <template #paginatorRight>Totale: {{ totalRecords }}</template>
      </v-data-table>

      <v-dialog v-model="dialogInfoVisible" max-width="400" scrollable>
        <v-card>
          <v-card-title>Dettagli</v-card-title>
          <v-card-text>
            <TosViewItemVuetify :item="infoItem" :columns="columnOptions" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogCustomizeVisible" :style="{ width: '700px' }" header="Configurazione" persistent>
        Colonne:
        <MultiSelect v-model="columns" :options="columnOptions" optionLabel="header" placeholder="Seleziona colonne" />
      </v-dialog>
      <!-- </div> -->
    </div>

    <div v-else>
      <v-card>
        <v-card-title v-if="item.id">{{ editLabel }}</v-card-title>
        <v-card-title v-else>{{ createLabel }}</v-card-title>
        <v-card-text class="editCardClass">
          <div v-if="editResponse">
            <template v-if="$scopedSlots.editF_errors">
              <slot name="editF_errors" :data="item" :editResponse="editResponse"></slot>
            </template>
            <template v-else>
              <span class="errorMessage" v-if="editResponse.message">{{
                editResponse.message
              }}</span>
              <ul>
                <li v-for="(message, field) of editResponse.errors" :key="field">
                  <template v-if="field != 'null'">
                    <b>{{ field }}</b>: {{ message }}
                  </template>
                  <template v-else>
                    {{ message }}
                  </template>
                </li>
              </ul>
            </template>
          </div>
          <v-form ref="editForm">
            <slot v-if="$scopedSlots.editF" name="editF" :data="item" :errors="errors"></slot>
            <TosEditFormVuetify v-else :item="item" :columns="columns" :editResponse="editResponse" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="listMode = true" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="saveItem" :disabled="loading"><v-icon class="pr-2">mdi-content-save</v-icon>{{ tLabel("Salva") }}</v-btn>         
        </v-card-actions>
      </v-card>
    </div>
    <v-dialog v-model="confirmDialogVisible" persistent max-width="290" header="Conferma">
      <v-card>
        <v-card-text>
          {{ confirmMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onConfirm" small>Si</v-btn>
          <v-btn @click="confirmDialogVisible = false" small>No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import DataTableMixin from "../../mixins/DataTableMixin";
import GenericMixin from "../../mixins/GenericMixin";

export default {
  name: "tosList",
  mixins: [DataTableMixin, GenericMixin],
  computed: {
    columnsHeader() {
      let c = [];
      if (this.columns == null) return [];
      c.push({ class: "header text-uppercase", value: "actions", sortable: "false" });
      this.columns.forEach(function (item) {
        c.push({ class: "header text-uppercase", ...item });
      });
      return c;
    },
    errors() {
      return (this.editResponse && this.editResponse.errors) || {};
    },
  },
  mounted() {
    //// prova riconoscimento filtri per tipo
    // console.log("refs customFiltersRef -------------------> ", this.$refs['customFiltersRef'].childNodes[0].childNodes);
  },
  methods: {
    hasItemSlot(field) {
      const existsItem = this.$scopedSlots["item." + field] != null;
      return existsItem;
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .p-datatable .p-sortable-column.p {
    border: 2px solid red;
  }
  .p-datatable .p-sortable-column.p-highlight {
    border: 3px solid red;
  }
  .errorMessage {
    color: red;
  }
}
</style>
