<template>
  <div class="p-cardialog-content">
    <div v-if="item" class="p-grid p-fluid" style="overflow:auto;  max-height: 500px;">
      <template v-for="col in cols">
        <div class="p-col-12 alignRight">
          <v-text-field readonly :label="col | humanize" :value="itemInfo[col]" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import RailTosService from "../../service/tos/RailTosService";

export default {
  name: "TosViewItem",
  data() {
    return {
      itemInfo: null,
      cols: []
    };
  },
  service: null,
  props: ["item", "columns"],
  filters: {
    humanize: function (camelCaseString) {
      const words = camelCaseString.match(/[A-Za-z][a-z]*/g) || [];
      const s = words.join(" ");
      return s.charAt(0).toUpperCase() + s.substring(1);
    }
  },
  watch: {
    item: function (newVal, oldVal) { // watch it
      this.service.getInfo(newVal).then(data => this.filterFields(data));
    }
  },
  created() {
    this.service = new RailTosService("");
  },
  mounted() {
    setTimeout(() => {
      this.service.getInfo(this.item).then(data => this.filterFields(data));
    }, 50);
  },
  methods: {
    filterFields(data) {
      console.log(data);
      this.itemInfo = data;
      this.cols = [];
      for (var col in data) {
        this.cols.push(col);
      }
    },
  }
};
</script>

<style scoped>
.alignRight {
  text-align: right;
}
</style>
