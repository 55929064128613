<template>
  <div v-if="item" class="p-cardialog-content" >
    <div class="p-grid p-fluid" style="overflow:auto;  max-height: 500px;">
      <template v-for="col in columns">
        <div v-bind:key="col.field" class="p-col-4">
          <v-datetime-picker v-if="col.columnType==='Date'" :label="col.text | humanize" v-model="item[col.value]" :error-messages="getRules(col.value)" />
          <v-text-field v-else-if="!(col.link)" :id="makeFormFieldId(col.value)" :label="col.text | humanize" v-model="item[col.value]" 
          :error-messages="getRules(col.value)" />
          <template v-else>
             <v-select :id="makeFormFieldId(col.value)" v-model="item[col.value+'Id']" :label="col.text | humanize" 
              :error-messages="getRules(col.value)"
              :items="listItems[col.columnType]" item-text="label" item-value="id" />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import RailTosService from "../../service/tos/RailTosService";

export default {
  name: "tosEditFormVuetify",
  data() {
    return {
        listItems:null,
    };
  },
  service: null,
  props: ["item", "columns", "editResponse"],
  filters: {
    humanize: function(camelCaseString) {
      if(!camelCaseString) return null;
      const words = camelCaseString.match(/[A-Za-z][a-z]*/g) || [];
      const s= words.join(" ");
      return s.charAt(0).toUpperCase() + s.substring(1);
    }
  },
  created() {
    this.service = new RailTosService("");
    this.addDateFields();
    this.retrieveList();
  },
  methods: {
    addDateFields() {
      //aggiunge campo Date per ogni colonna date
      const item = this.item;
      this.columns.forEach(function(col) {
        const v = item[col.field];
        if (col.columnType === "Date" && v != null) {
          item[col.field] = new Date(parseInt(v));
        }
      });
    },
    retrieveList() {
        console.log("--- retrieveList");
        this.listItems={};
        const that=this;
        this.columns.forEach(function(col) {
            if (col.link) {
                that.listItems[col.columnType]=null;
            }
        });
        for(var key in this.listItems){
            console.log("Retrieving list of ", key);
            setTimeout(() => {
                this.service.getShortList(key).then(data => {
                    that.listItems[key]=[{id:null, label:''}, ...data.data];
                    that.listItems={...that.listItems};
                });
            }, 1000);
        }
    },
    makeFormFieldId: function(field) {
      return "form_" + field;
    },
    classError(field){
      return this.editResponse && this.editResponse.errors && this.editResponse.errors[field] ? 'error' : '';
    },
    getRules(field){
      return this.editResponse && this.editResponse.errors && this.editResponse.errors[field] ? this.editResponse.errors[field] : null;
    },
  }
};
</script>

<style scoped>
.alignRight {
  text-align: right;
}
.error {
  border: 1px solid red;
}
</style>
